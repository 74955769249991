.Projet {
  padding: 2rem 1.5rem;
  margin-bottom: 2rem;
}
.Projet p {
  margin-bottom: 1.5rem;
  color: #5b5855;
}

.Articles {
  margin-bottom: 1.5rem;
}
.ArticlesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Description p {
  text-align: justify;
}

.Description ul {
  margin-bottom: 1.5rem;
  margin-top: -0.5rem;
  margin-left: 2rem;
}
.Description ul li {
  margin-bottom: 0.4rem;
  color: #5b5855;
  font-weight: bold;
}

.ActionButton {
  display: none;
}
.ActionButton p,
.ActionButtonOutside p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
  color: white;
  background: #0095ba;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  margin-top: 4rem;
}
.ActionButtonSvg {
  display: none;
}

.DescriptionSvg {
  max-width: 20rem;
  margin: 0 auto;
}

.VisiteVirtuelle {
  text-align: center;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Projet {
    display: flex;
    flex-flow: row;
    padding: 4rem 4rem;
  }
  .Description {
    margin-right: 5rem;
    padding-top: 3rem;
  }
  .DescriptionSvg {
    display: none;
  }
  .DescriptionSection {
    position: relative;
  }
  .Description {
    /* margin-left: 13vw; */
    margin-right: 5vw;
  }

  .ActionButton {
    display: none;
  }

  .BackgroundShape {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Projet {
    padding: 4rem 11rem;
    padding-right: 5rem;
  }

  .DescriptionSection {
    position: relative;
  }
  .Description {
    margin-left: 13vw;
    margin-right: 5vw;
  }
  .DescriptionSvg {
    display: block;
    width: 25vw;
    position: absolute;
    left: -11rem;
    top: -3rem;
    z-index: -1;
  }

  .ActionButton {
    display: block;
    position: relative;
  }

  .ActionButtonSvg {
    display: block;
    width: 15rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
  }
  .ActionButtonSvg::before {
    content: "";
    transform: translate(-50%, 50%);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .BackgroundShape {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}
