.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.LogoContainer {
  width: 15em;
  margin: 1em 0;
}
.LogoContainer img {
  width: 100%;
}
.LegalLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
}
.LegalLinks p {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 0.8rem;
  color: #828282;
  padding: 0.8rem 1rem;
  background-color: #f3f2f0;
  border-radius: 10px;
}
.Copyrights {
  margin: 0.5rem 0;
  color: #5b5855;
  text-align: center;
  font-size: 0.8rem;
}
.Copyrights span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
}
.BonusLogos {
  margin: 0 auto;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.ImmobiliereLogo {
  width: 12rem;
  margin: 1rem auto;
}
