@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "PT Sans", sans-serif;
  background: #f3f2f0;
}

@font-face {
  font-family: "Futura Extra Bold";
  src: local("Futura Extra Bold"),
    url(/static/media/Futura_Extra_Bold.db7c22c7.otf) format("truetype");
}

.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

/* .App {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 3rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Login_Wrapper__2P1ui {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.Login_LoginContainer__2CBJt {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.Login_Login__3luNv {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.Login_ImgWrapper__3JFLL {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.Login_ImgWrapper__3JFLL img {
  width: 100%;
}

.Login_LoginTitle__2ipQy {
  font-size: 2em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 40px;
}
.Login_LoginTitle__2ipQy span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ====FORM STYLING START====== */
.Login_InputGroup__3yDNc {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Login_Input__36Erh {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Login_Input__36Erh:focus {
  outline: none;
}
.Login_Phone__2fr6a {
  padding-left: 45px;
}
.Login_InputGroup__3yDNc i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
.Login_MUIInputGroup__398sy {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.Login_InputError__1mtTd {
  font-size: 0.85rem;
  color: #e0004d;
  margin-top: 0.5em;
}
/* LABEL ======= */
.Login_InputLabel__2Gidy {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Login_Input__36Erh:focus ~ .Login_InputLabel__2Gidy,
.Login_Input__36Erh:valid ~ .Login_InputLabel__2Gidy {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Login_Bar__3PMgB {
  position: relative;
  display: block;
  width: 100%;
}
.Login_Bar__3PMgB:before,
.Login_Bar__3PMgB:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Login_Bar__3PMgB:before {
  left: 50%;
}
.Login_Bar__3PMgB:after {
  right: 50%;
}
/* active state */
.Login_Input__36Erh:focus ~ .Login_Bar__3PMgB:before,
.Login_Input__36Erh:focus ~ .Login_Bar__3PMgB:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Login_Highlight__13YSC {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Login_Input__36Erh:focus ~ .Login_Highlight__13YSC {
  animation: Login_inputHighlighter__1x7B8 0.3s ease;
  -webkit-animation: Login_inputHighlighter__1x7B8 0.3s ease;
  -moz-animation: Login_inputHighlighter__1x7B8 0.3s ease;
}
/* Animations */
@keyframes Login_inputHighlighter__1x7B8 {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Login_inputHighlighter__1x7B8 {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Login_Indicatif__3-Zpz {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Login_Input__36Erh:focus ~ .Login_Indicatif__3-Zpz,
.Login_Input__36Erh:valid ~ .Login_Indicatif__3-Zpz {
  opacity: 1;
}

/* ========= CGU CHECKBOX =========== */

.Login_CGUGroup__v75CX input[type="checkbox"] {
  display: none;
}
.Login_CGUGroup__v75CX input[type="checkbox"] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

.Login_CGUGroup__v75CX input[type="checkbox"]:checked + label:before {
  content: "\f046";
}
.Login_CGUGroup__v75CX input[type="checkbox"]:checked + label:before {
  letter-spacing: 8px;
}

.Login_CGUGroup__v75CX input[type="checkbox"] + label:before {
  content: "\f096";
}
.Login_CGUGroup__v75CX input[type="checkbox"] + label:before {
  letter-spacing: 10px;
}

/* CGU Link ====== */
.Login_CGUGroup__v75CX label {
  color: #828489;
  font-size: 0.9em;
}
.Login_CGUGroup__v75CX span {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ======SUBMIT BUTTON ====== */
.Login_SubmitButtonContainer__PFAhZ {
  margin-top: 40px;
}

/* Anon link */
.Login_AnonLogin__27AhB {
  margin-top: 1em;
  text-align: center;
}

.RefInfo_RefInfoTitle__2Iwra {
  display: flex;
}
.RefInfo_RefInfoTitle__2Iwra h3 {
  margin-left: 8px;
}

.RefInfo_RefWrapper__18oqI img {
  width: 100%;
  margin-top: 15px;
}

.CGU_CGUTitle__3P3oz {
  text-align: center;
}

.CGU_CGUWrapper__3LYBB {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.CGU_Article__1M7YM {
  margin-bottom: 1em;
}

.CGU_ArticleTitle__z7Nbg {
  margin-bottom: 0.5em;
}
.CGU_ArticleSubtitle__2Gcmz {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__29b2- {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__29b2- p {
  margin-bottom: 0.3em;
}

.Modal_Modal__1Jc6- {
  position: fixed;
  z-index: 500;
  background: white;
  border-radius: 9px;
  width: 90%;
  padding: 16px;
  left: 5%;
  top: 30%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal_Modal__1Jc6- {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.Backdrop_Backdrop__1mcjb {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  /* background-color: rgba(229, 229, 229, 0.8); */
  background-color: rgba(0, 0, 0, 0.5);
}

.SubmitButton_SubmitButton__3gHe9 {
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 1em 1em;
  min-width: 10em;
  border: 0;
  outline: 0;
  font-size: 0.9rem;
  text-align: center;
  /* Primaire Rouge 1 */
  background: #e0004d;
  /* 6dp elevation */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 6px 10px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.SubmitButton_SubmitButton__3gHe9:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.SubmitButton_SubmitButton__3gHe9:disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

/* Invalid state */
.SubmitButton_Invalid__1hnCr i {
  -webkit-transform: translate(-30vw, 11vh);
          transform: translate(-30vw, 11vh);
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
}
.SubmitButton_Invalid__1hnCr span {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* transition: all linear 0.2s; */
}

/* Valid state */
.SubmitButton_Valid__1_C71 i {
  color: #ffc300;
  font-size: 1.4em;
  margin-left: 0.4em;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 1;
}

/* Loading state */
.SubmitButton_Clicked__1BFcX i {
  -webkit-transform: translate(100vw, -90vh);
          transform: translate(100vw, -90vh);
  -webkit-transition: all linear 1.3s;
  transition: all linear 1.3s;
}

.CircularLoading_CircularLoading__1IOSH {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Home_SubNavbar__1__Iv {
  margin: 0 1.5rem;
  display: flex;
  justify-content: space-between;
}
.Home_SubNavbar__1__Iv p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
}
.Home_ActionButton__3SQvj p {
  color: white;
  background: #8abf56;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  position: relative;
}
.Home_ActionButtonSvg__1SQE4 {
  display: none;
}
/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Home_SubNavbar__1__Iv {
    margin: 0 4rem;
  }
  .Home_ActionButtonSvg__1SQE4 {
    display: block;
    width: 15rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: absolute;
    top: -35rem;
    right: 10rem;
    right: 3rem;
  }
  .Home_Footer__3Vmxo {
    width: 100%;
    margin-top: 6rem;
    padding-right: 5rem;
    display: flex;
    justify-content: flex-end;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Home_SubNavbar__1__Iv {
    margin: 0 11rem;
  }
  .Home_ActionButtonSvg__1SQE4 {
    right: 10rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.HomeToolbar_Header__2nyo9 {
  background-color: #f3f2f0;
  padding: 2rem 1.5rem;
}

.HomeToolbar_Toolbar__8Eu0N {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.HomeToolbar_Logo__MG8vl {
  width: 5rem;
  margin-top: 1rem;
}

.HomeToolbar_Title__5IWzg {
  margin-top: 1.5rem;
  font-family: "Nunito Sans";
  line-height: 1.7rem;
}
.HomeToolbar_Title__5IWzg h3 {
  font-size: 1.8rem;
  color: #292f33;
  text-transform: uppercase;
  font-weight: 900;
}
.HomeToolbar_Title__5IWzg h4 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #5b5855;
}

.HomeToolbar_Description__3FGOn {
  color: #5b5855;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 1.125rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .HomeToolbar_Header__2nyo9 {
    padding: 2rem 4rem;
  }
  .HomeToolbar_Toolbar__8Eu0N {
    align-items: center;
    justify-content: left;
    grid-gap: 2rem;
    gap: 2rem;
  }
  .HomeToolbar_Logo__MG8vl {
    width: 5rem;
    margin: 0;
  }
  .HomeToolbar_Title__5IWzg h3 {
    font-size: 1.8rem;
  }
  .HomeToolbar_Title__5IWzg {
    display: flex;
    flex-direction: column;
  }
  .HomeToolbar_Title__5IWzg h4 {
    margin-left: 0;
  }
  .HomeToolbar_Description__3FGOn {
    width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .HomeToolbar_Header__2nyo9 {
    padding: 2rem 11rem;
  }
  .HomeToolbar_Logo__MG8vl {
    margin-left: 2rem;
  }

  .HomeToolbar_Title__5IWzg h3 {
    font-size: 2rem;
  }
}

.HomeFooter_Footer__26C73 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HomeFooter_LogoContainer__3Ehnk {
  width: 15em;
  margin: 1em 0;
}
.HomeFooter_LogoContainer__3Ehnk img {
  width: 100%;
}
.HomeFooter_LegalLinks__1lx0t {
  display: flex;
  margin-top: 1em;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
}
.HomeFooter_LegalLinks__1lx0t p {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 0.8rem;
  color: #828282;
  padding: 0.8rem 1rem;
  background-color: #f3f2f0;
  border-radius: 10px;
}
.HomeFooter_Copyrights__2-0d0 {
  margin: 1em 0;
  color: #5b5855;
  text-align: center;
  font-size: 0.8rem;
}
.HomeFooter_Copyrights__2-0d0 span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
}

.HomeFooter_BonusLogos__pG8ex {
  margin: 0 auto;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.HomeFooter_ImmobiliereLogo__2Ff0z {
  margin-top: 1rem;
  width: 12rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.ViewSelector_ViewSelector__1hHMu {
  display: flex;
  justify-content: space-between;
}
.ViewSelector_ViewSelector__1hHMu p {
  border-radius: 30px;
  cursor: pointer;
  box-sizing: border-box;
}
.ViewSelector_UnselectedView__20qJx {
  color: #292f33;
  background: #f3f2f0;
  border: 1px solid #0095ba;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
}
.ViewSelector_SelectedView__XBxv9 {
  background: #0095ba;
  color: white;
  border: 1px solid #0095ba;
}
/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ViewSelector_ViewSelector__1hHMu {
    width: 15rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarDesktop_Calendar__15EZj {
  margin: 4rem 4rem;
}

.CalendarDesktop_CalendarTitle__1BIF1 {
  font-size: 1.25rem;
  color: #292f33;
}
.CalendarDesktop_CalendarTitle__1BIF1 span {
  font-weight: 700;
}

.CalendarDesktop_Main__3_XDw {
  display: flex;
  margin-top: 2rem;
}

.CalendarDesktop_CardsList__2A5k- {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.CalendarDesktop_Modal__1d8Vd {
  min-width: 21rem;
  max-width: 21rem;
}
.CalendarDesktop_PlanSchematique__1sRhy {
  /* background-color: white; */
  /* border-radius: 15px; */
  width: 70%;
  margin: 0 auto;
  margin-top: 2rem;
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .CalendarDesktop_Calendar__15EZj {
    margin: 4rem 11rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarCard_Card__2Too0 {
  max-width: 15rem;
  min-width: 15rem;
  max-height: 10rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.CalendarCard_Emoji__3ltX9 {
  font-size: 2rem;
  margin: auto;
}
.CalendarCard_Card__2Too0 p {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
}
.CalendarCard_SelectedCard__2bG6c {
  background: #0095ba;
  border-radius: 18px;
  color: white;
  max-width: 15rem;
  min-width: 15rem;
  max-height: 10rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.CalendarCard_SelectedCard__2bG6c p {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarModal_CalendarModal__VKCdm {
  background: #ffffff;
  border-radius: 18px;
  height: 30rem;
  overflow-y: scroll;
  /*-ms-overflow-style: none;  IE and Edge */
  /*scrollbar-width: none;  Firefox */
}
/* .CalendarModal::-webkit-scrollbar {
  display: none;
} */

.CalendarModal_StepTitle__3RZem {
  background-color: #0095ba;
  color: white;
  border-radius: 18px 18px 0 0;
  display: flex;
  padding: 1rem;
  font-size: 1.18rem;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
}
.CalendarModal_TitleEmoji__5T5iy {
  font-size: 3rem;
  margin-right: 1rem;
  margin-top: -0.5rem;
}
.CalendarModal_Substeps__3PVQH {
  padding: 1rem 1rem;
}
.CalendarModal_Substep__1fgfp {
  color: #292f33;
  font-size: 1rem;
  margin-bottom: 2rem;
}
.CalendarModal_Description__1bAh9 {
  display: flex;
  margin-bottom: 0.3rem;
}
.CalendarModal_Emoji__3OQbZ {
  font-size: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}

.CalendarModal_Plan__22aYE {
  margin-bottom: 2rem;
  text-align: center;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarMobile_Calendar__2x1-f {
  margin: 4rem 4rem;
}

.CalendarMobile_CalendarTitle__1olHL {
  font-size: 1.25rem;
  color: #292f33;
}
.CalendarMobile_CalendarTitle__1olHL span {
  font-weight: 700;
}

.CalendarMobile_Main__2trSD {
  display: flex;
  margin-top: 2rem;
}

.CalendarMobile_CardsList__2Xorh {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.CalendarMobile_Modal__1erof {
  width: 80%;
  outline: none;
}
.CalendarMobile_PlanSchematique__ehPeb {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .CalendarMobile_Calendar__2x1-f {
    margin: 4rem 11rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.Projet_Projet__1P-jj {
  padding: 2rem 1.5rem;
  margin-bottom: 2rem;
}
.Projet_Projet__1P-jj p {
  margin-bottom: 1.5rem;
  color: #5b5855;
}

.Projet_Articles__2M5wj {
  margin-bottom: 1.5rem;
}
.Projet_ArticlesHeader__2Qh9u {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Projet_Description__2Li51 p {
  text-align: justify;
}

.Projet_Description__2Li51 ul {
  margin-bottom: 1.5rem;
  margin-top: -0.5rem;
  margin-left: 2rem;
}
.Projet_Description__2Li51 ul li {
  margin-bottom: 0.4rem;
  color: #5b5855;
  font-weight: bold;
}

.Projet_ActionButton__2ZSXI {
  display: none;
}
.Projet_ActionButton__2ZSXI p,
.Projet_ActionButtonOutside__2i6NE p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
  color: white;
  background: #0095ba;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-top: 4rem;
}
.Projet_ActionButtonSvg__1N_Qm {
  display: none;
}

.Projet_DescriptionSvg__XmtXi {
  max-width: 20rem;
  margin: 0 auto;
}

.Projet_VisiteVirtuelle__v5ZL- {
  text-align: center;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Projet_Projet__1P-jj {
    display: flex;
    flex-flow: row;
    padding: 4rem 4rem;
  }
  .Projet_Description__2Li51 {
    margin-right: 5rem;
    padding-top: 3rem;
  }
  .Projet_DescriptionSvg__XmtXi {
    display: none;
  }
  .Projet_DescriptionSection__2H3Lu {
    position: relative;
  }
  .Projet_Description__2Li51 {
    /* margin-left: 13vw; */
    margin-right: 5vw;
  }

  .Projet_ActionButton__2ZSXI {
    display: none;
  }

  .Projet_BackgroundShape__ljHBA {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Projet_Projet__1P-jj {
    padding: 4rem 11rem;
    padding-right: 5rem;
  }

  .Projet_DescriptionSection__2H3Lu {
    position: relative;
  }
  .Projet_Description__2Li51 {
    margin-left: 13vw;
    margin-right: 5vw;
  }
  .Projet_DescriptionSvg__XmtXi {
    display: block;
    width: 25vw;
    position: absolute;
    left: -11rem;
    top: -3rem;
    z-index: -1;
  }

  .Projet_ActionButton__2ZSXI {
    display: block;
    position: relative;
  }

  .Projet_ActionButtonSvg__1N_Qm {
    display: block;
    width: 15rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
  }
  .Projet_ActionButtonSvg__1N_Qm::before {
    content: "";
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .Projet_BackgroundShape__ljHBA {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ArticleModal_ArticleModal__1g7Mk {
  padding: 0;
  border-radius: 10px;

  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ArticleModal_ArticleModal__1g7Mk::-webkit-scrollbar {
  display: none;
}

.ArticleModal_ModalContent__31Dbj {
  width: 80%;
  outline: none;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ArticleModal_ModalContent__31Dbj {
    width: 50%;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .ArticleModal_ModalContent__31Dbj {
    width: 40%;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.Articles_ArticleCard__3mAE3 {
  background: #ffffff;
  padding: 1rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 22rem;
}
.Articles_ArticleCard__3mAE3 p {
  margin: 0;
  color: #292f33;
  font-size: 1rem;
}
.Articles_Emoji__I7x4I {
  margin-right: 0.5rem;
  font-size: 1.8rem;
}
.Articles_DeleteButton__3lzJG {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Articles_ArticleCard__3mAE3 {
    min-width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Articles_ArticleCard__3mAE3 {
    min-width: 20rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.UploadDocument_AddButton__3Re82 p {
  background: #ee9834;
  color: white;
  border: 1px solid #ee9834;
  cursor: pointer;
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
}
.UploadDocument_ModalContent__1u4EM {
  background-color: #f3f2f0;
  padding: 2rem;
  border-radius: 10px;
  width: 30rem;
  outline: none;
}
.UploadDocument_QuestionTitle__37B5p {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.UploadDocument_QuestionTitle__37B5p p {
  color: #2a2f39;
  margin-left: 0.4em;
}
.UploadDocument_TitleField__5PB8Y {
  margin-top: 1rem;
}
.UploadDocument_CloudIcon__3omTl {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.6rem;
  justify-content: center;
}
.UploadDocument_Buttons__bRKrW {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .UploadDocument_ModalContent__1u4EM {
    width: 80%;
    outline: none;
  }
}

.ProfileSetup_Wrapper__cBskA {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.ProfileSetup_ProfileSetupContainer__ANvbb {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.ProfileSetup_ProfileSetup__2WIda {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.ProfileSetup_ImgWrapper__3VdQO {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.ProfileSetup_ImgWrapper__3VdQO img {
  width: 100%;
}

.ProfileSetup_Title__3UM6i {
  font-size: 1.5em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 10px;
}
.ProfileSetup_Title__3UM6i span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}
.ProfileSetup_CircularProgress__2f1Gy {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ProfileSetup_QuestionContainer__xNQa8 {
  height: 15rem;
  margin-bottom: 3.5rem;
}

/* START =========== Buttons */
.ProfileSetup_ButtonsContainer__1xGZ4 {
  position: absolute;
  left: 0;
  bottom: 2rem;
  right: 0;
  text-align: center;
}
.ProfileSetup_Button__1VHyK {
  display: inline-block;
  margin: 0 1rem;
}
.ProfileSetup_SubmitButton__1vYYg {
  line-height: 0;
  width: auto;
  padding: 0 12px;
  height: 48px;
  margin: 0 8px;
  font-size: 1.2rem;

  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;

  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.NextButton_NextButton__2Coxa {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: #3d9ec73f;
  border: none;
  border-radius: 50px;
}

.NextButton_NextButton__2Coxa:hover {
  background-color: #3d9dc7;
  color: white;
}

.NextButton_NextButton__2Coxa:active {
  background-color: #3d9dc7;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.NextButton_NextButton__2Coxa i {
  padding-left: 2px;
}

.BackButton_BackButton__1Lmb9 {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: #3d9ec73f;
  border: none;
  border-radius: 50px;
}

.BackButton_BackButton__1Lmb9:hover {
  background-color: #3d9dc7;
  color: white;
}

.BackButton_BackButton__1Lmb9:active {
  background-color: #3d9dc7;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.BackButton_BackButton__1Lmb9 i {
  padding-right: 2px;
}

.CircularProgressBar_Circle__15E5V {
  position: relative;
  width: 50px;
  height: 50px;
  border: 4px solid #c9d100;
  box-sizing: border-box;
  border-radius: 50%;
  /* animation-name: progress;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Circle__15E5V::after {
  content: "";
  position: absolute;
  top: -21px;
  left: -21px;
  width: 180px;
  height: 180px;
  border: 22px solid #fff;
  box-sizing: border-box;
  border-radius: 50%;
  -webkit-clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
          clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
  /* animation-name: progress_after;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Etape__KFhqV {
  margin-top: 0.35em;
  font-weight: bold;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

.QuestionTwo_QuestionTwo__2cspb {
  margin: 1.3em 0;
  text-align: center;
}
.QuestionTwo_QuestionTwo__2cspb p {
  margin: 20px;
  font-size: 1.1rem;
}

.Radio_FormTitle__2fSXy {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  text-align: center;
}

.Radio_Form__1-to1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Radio_FormElement__aIfQ7 {
  margin: 5px 5px;
  width: 3.2em;
  height: 3.2em;
  position: relative;
}

.Radio_FormElement__aIfQ7 input[type="radio"] {
  display: none;
}

.Radio_FormElement__aIfQ7 label {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 15%;
  border: none;
  background-color: #3d9ec756;
  color: #777;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Radio_FormElement__aIfQ7 input[type="radio"]:checked + label {
  background-color: #3d9dc7;
  color: white;
}

/* .Travaux {
  overflow-x: hidden;
} */
.Travaux_SubHeader__2VwiH {
  background-color: #8abf56;
  padding: 1rem 0;
  margin-top: -0.1rem;
}
.Travaux_HomeLink__1b3gU {
  color: white;
  display: flex;
  justify-content: center;
}

.Travaux_HomeLink__1b3gU a {
  color: white;
  font-size: 0.9rem;
  margin-left: 0.4rem;
  text-decoration: underline;
  display: flex;
  align-items: center;
}
.Travaux_Main__1imJB {
  padding: 0 0.2em;
  margin-bottom: 1rem;
}
.Travaux_CardListTitle__12hq7 {
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Travaux_CardListTitle__12hq7 p {
  font-size: 1.2rem;
  text-align: center;
  color: #828489;
  margin-bottom: 1rem;
}
.Travaux_CardListTitle__12hq7 span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #292f33;
}
.Travaux_Travaux__DX78T {
  /* margin-bottom: 4em; */
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Travaux_SubHeader__2VwiH {
    padding: 1rem 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Travaux_CardListTitle__12hq7 {
    flex-direction: row;
    justify-content: space-between;
  }
  .Travaux_CardListTitle__12hq7 p {
    font-size: 1.4rem;
  }
  .Travaux_CardListTitle__12hq7 span {
    font-size: 1.4rem;
  }
  .Travaux_Travaux__DX78T {
    margin: 0 4rem;
    margin-top: 1em;
    /* margin-bottom: 4em; */
  }
  .Travaux_MainCards__21QhC {
    display: flex;
    position: relative;
    min-height: 20em;
  }
  /* .NoCard {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  } */
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Travaux_SubHeader__2VwiH {
    padding: 1rem 11rem;
  }
  .Travaux_Travaux__DX78T {
    margin: 0 11em;
    /* margin-bottom: 4em; */
  }
}

.Toolbar_Header__sc6WC {
  background-color: #0095ba;
  padding: 2rem 1.5rem;
}

.Toolbar_Toolbar__1qQew {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Toolbar_Title__3-BM1 {
  margin-top: 1.5rem;
  color: white;
  font-family: "Nunito Sans";
  line-height: 1.7rem;
}
.Toolbar_Title__3-BM1 h3 {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 900;
}
.Toolbar_Title__3-BM1 h4 {
  font-size: 1.6rem;
  font-weight: 600;
}

.Toolbar_Description__3_J2f {
  color: white;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.125rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Toolbar_Header__sc6WC {
    padding: 4rem 4rem;
  }
  .Toolbar_Title__3-BM1 h3 {
    font-size: 1.8rem;
  }
  .Toolbar_Title__3-BM1 {
    display: flex;
  }
  .Toolbar_Title__3-BM1 h4 {
    margin-left: 1rem;
  }
  .Toolbar_Description__3_J2f {
    width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Header__sc6WC {
    padding: 2rem 11rem;
  }

  .Toolbar_Title__3-BM1 h3 {
    font-size: 2rem;
  }
}

.Filter_Filter__2wm1k {
}
.Filter_Filter__2wm1k p {
  font-size: 0.9rem;
}
.Filter_Filter__2wm1k span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Filter_Categories__27-2P form {
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 1rem 0;
  /* flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; 
  scrollbar-width: none;  */
}
/* .Categories form::-webkit-scrollbar {
  display: none; 
} */

.Filter_Personnalisable__12DKK {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Personnalisable__12DKK label {
  background: #bcbecc;
  color: #5b5855;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Personnalisable__12DKK input[type="checkbox"] {
  display: none;
}

.Filter_Personnalisable__12DKK input[type="checkbox"]:checked + label {
  background: white;
  color: #5b5855;
}

/* =========================== */
.Filter_Informative__3iOkd {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Informative__3iOkd label {
  background: #bcbecc;
  color: #5b5855;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Informative__3iOkd input[type="checkbox"] {
  display: none;
}

.Filter_Informative__3iOkd input[type="checkbox"]:checked + label {
  background: white;
  color: #5b5855;
}

/* .CardList {
  margin-bottom: 2em;
}

.List {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* large tablets & laptops */
/* @media screen and (min-width: 768px) {
  .List {
    align-items: flex-start;
    width: 21em;
  }
} */

.CardList_CardList__3-Y4Z {
  margin-bottom: 2em;
}

.CardList_List__LZ5kE {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  /* .List {
    align-items: flex-start;
    width: 21em;
  } */
}

.Card_Card__hQYl2 {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.Card_SelectedCard__15nK3 {
  border: 2px solid #e57373;
}

.Card_Thumbnail__2ZB5h {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  display: flex;
}
.Card_Thumbnail__2ZB5h img {
  border-radius: 14px;
  width: 100%;
}

.Card_Main__3kTQj {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Card_Titre__IGgi1 {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.Card_Titre__IGgi1 span {
  font-weight: 700;
}

.Card_Description__3TEFT {
  margin-right: 4em;
  font-size: 0.6rem;
}

.Card_Icon__3fwW7 {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.Card_TagsContainer__2SbQ0 {
  display: flex;
}
.Card_TagContainer__26d6G {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 1rem;
}
.Card_Tag__tXaY4 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.Card_Tag__tXaY4 p {
  padding: 0 0.2rem;
}

.Card_Notification__1_ncX {
  position: absolute;
  top: -0.8rem;
  left: 1rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 1.6rem;
  background-color: #8abf56;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CardCreateReclamation_Card__3c5yZ {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;

  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.CardCreateReclamation_SelectedCard__u3YMZ {
  border: 2px solid #e57373;
}

.CardCreateReclamation_Thumbnail__3g7ia {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardCreateReclamation_Thumbnail__3g7ia img {
  border-radius: 14px;
  width: 100%;
}

.CardCreateReclamation_Main__1qffE {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardCreateReclamation_Titre__5Y9on {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardCreateReclamation_Titre__5Y9on span {
  font-weight: 700;
}

.CardCreateReclamation_Description__11q8G {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardCreateReclamation_Icon__1benP {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardCreateReclamation_TagContainer__3qQ34 {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardCreateReclamation_Tag__HW3fQ {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardCreateReclamation_Tag__HW3fQ p {
  padding: 0 0.2rem;
}

.ModalCreateReclamation_ModalCreateReclamation__2YEPQ {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalCreateReclamation_ModalCreateReclamation__2YEPQ::-webkit-scrollbar {
  display: none;
}

.ModalCreateReclamation_ModalContent__y9hvx {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalCreateReclamation_ModalTitle__37423 {
  margin-top: 1.4rem;
  margin-bottom: 1.7rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b4578;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalCreateReclamation_ModalContent__y9hvx {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__1Rdfc {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__2kTDA {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__3XJIj {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__3XJIj p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__2UIH2 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__3m-QY {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__3m-QY input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__3m-QY input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__3m-QY input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__3m-QY input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__3m-QY input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__3m-QY input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__3m-QY input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__3m-QY input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__3FZw4 {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__3svdx {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__3WxXg {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__2uXTG {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__1qBDV {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ReclamationContent_RecapTags__1vRiG {
  display: flex;
}
.ReclamationContent_RecapTags__1vRiG p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__PFaIp {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__2buw- {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__2buw-::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__3T1NF {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__1CyL6 {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 7rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__1CyL6::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__3YmLo {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__3m-QY {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.CardUserReclamation_Card__Rz_nW {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}
.CardUserReclamation_SelectedCard__11phG {
  border: 2px solid #e57373;
}

.CardUserReclamation_Thumbnail__xOFGP {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardUserReclamation_Thumbnail__xOFGP img {
  border-radius: 14px;
  width: 100%;
}

.CardUserReclamation_Main__3l4aY {
  margin: 0.5em 0;
  min-width: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardUserReclamation_Titre__lUYGo {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardUserReclamation_Titre__lUYGo span {
  font-weight: 700;
}

.CardUserReclamation_Description__2PkU7 {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardUserReclamation_Icon__abl3d {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardUserReclamation_TagContainer__7CXX3 {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardUserReclamation_Tag__3ilYD {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardUserReclamation_Tag__3ilYD p {
  padding: 0 0.2rem;
}

.CardUserReclamation_Notification__30JUA {
  position: absolute;
  top: -1.2rem;
  left: 1rem;
  padding: 0.3rem;
  border-radius: 100px;
  background-color: #fcb34a;
}

.ModalUserReclamation_ModalCreateReclamation__35wLy {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalUserReclamation_ModalCreateReclamation__35wLy::-webkit-scrollbar {
  display: none;
}

.ModalUserReclamation_ModalContent__1mGcP {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalUserReclamation_ModalTitle__2M5h_ {
  margin-top: 1.4rem;
  margin-bottom: 1.7rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b4578;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalUserReclamation_ModalContent__1mGcP {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__1dRx4 {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__2w7Xh {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__2qlTN {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__2qlTN p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__knC8V {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__20ZlY {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__20ZlY input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__20ZlY input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__20ZlY input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__20ZlY input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__20ZlY input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__20ZlY input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__20ZlY input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__20ZlY input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__1ZbQ2 {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__2YXOq {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__1r-5d {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__1iIB6 {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__3FbCD {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ReclamationContent_RecapTags__3ZiTf {
  display: flex;
}
.ReclamationContent_RecapTags__3ZiTf p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__1IQyc {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__1ozH9 {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__1ozH9::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__2G1pd {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__2lLRY {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 7rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__2lLRY::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__MiTJx {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__20ZlY {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.CardAdminReclamation_Card__2nJy4 {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}
.CardAdminReclamation_SelectedCard__3TCk7 {
  border: 2px solid #e57373;
}

.CardAdminReclamation_Thumbnail__18gDi {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardAdminReclamation_Thumbnail__18gDi img {
  border-radius: 14px;
  width: 100%;
}

.CardAdminReclamation_Main__3JIoe {
  margin: 0.5em 0;
  min-width: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardAdminReclamation_Titre__1LnT8 {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardAdminReclamation_Titre__1LnT8 span {
  font-weight: 700;
}

.CardAdminReclamation_Description__3uZkA {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardAdminReclamation_Icon__2QENi {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardAdminReclamation_TagContainer__128q4 {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardAdminReclamation_Tag__2wiAz {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardAdminReclamation_Tag__2wiAz p {
  padding: 0 0.2rem;
}

.CardAdminReclamation_Notification__1orVy {
  position: absolute;
  top: -1.2rem;
  left: 1rem;
  padding: 0.3rem;
  border-radius: 100px;
  background-color: #fcb34a;
}

.ModalUserReclamation_ModalCreateReclamation__3exZF {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalUserReclamation_ModalCreateReclamation__3exZF::-webkit-scrollbar {
  display: none;
}

.ModalUserReclamation_ModalContent__3gfuh {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalUserReclamation_ModalTitle__PKtAp {
  margin-top: -0.5rem;
  margin-bottom: 1.7rem;
  font-size: 0.9rem;
  font-weight: 700;
  color: #3b4578;
  display: flex;
  justify-content: space-between;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalUserReclamation_ModalContent__3gfuh {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__1LnAP {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__2YUP6 {
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__13hSu {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__13hSu p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__kva4Z {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__13-sT {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__13-sT input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__13-sT input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__13-sT input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__13-sT input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__13-sT input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__13-sT input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__13-sT input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__13-sT input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__Z0UYb {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__2jtDV {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__DPjVe {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__1tx41 {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__Hv5Q0 {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.ReclamationContent_RecapTags__3N-xi {
  display: flex;
}
.ReclamationContent_RecapTags__3N-xi p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__1uHUp {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__KuAkC {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__KuAkC::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__3mGj- {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__2oNk8 {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__2oNk8::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__2r5Zo {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__13-sT {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.Footer_Footer__3lXYW {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Footer_LogoContainer__3c45T {
  width: 15em;
  margin: 1em 0;
}
.Footer_LogoContainer__3c45T img {
  width: 100%;
}
.Footer_LegalLinks__3tk4Q {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
}
.Footer_LegalLinks__3tk4Q p {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 0.8rem;
  color: #828282;
  padding: 0.8rem 1rem;
  background-color: #f3f2f0;
  border-radius: 10px;
}
.Footer_Copyrights__2Ag9B {
  margin: 0.5rem 0;
  color: #5b5855;
  text-align: center;
  font-size: 0.8rem;
}
.Footer_Copyrights__2Ag9B span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
}
.Footer_BonusLogos__7qva8 {
  margin: 0 auto;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}
.Footer_ImmobiliereLogo__14iIr {
  width: 12rem;
  margin: 1rem auto;
}

.PDC_CGUTitle__3OIUW {
  text-align: center;
}

.PDC_CGUWrapper__Px3gF {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.PDC_Article__1LeSu {
  margin-bottom: 1em;
}

.PDC_ArticleTitle__wnROl {
  margin-bottom: 0.5em;
}
.PDC_ArticleSubtitle__2QnmW {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__1CEDz {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__1CEDz p {
  margin-bottom: 0.3em;
}

.CardModal_CardModal__10Sx1 {
  position: fixed;
  z-index: 500;
  background: white;
  overflow-y: scroll;
  /* border-radius: 9px; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .CardModal_CardModal__10Sx1 {
    position: static;
    overflow-y: hidden;
    background-color: #f3f2f0;
    width: auto;
    height: auto;
    padding: 2em;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1400px) {
  .CardModal_CardModal__10Sx1 {
    padding: 0;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
  }
}

.NonConcertableLayoutOne_Card__1PqkS {
  background-color: white;
  outline: none;
  overflow: scroll;
}

.NonConcertableLayoutOne_CardHeader__H_1_K {
  display: flex;
  top: 0;
  left: 0;
  margin: 2rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.NonConcertableLayoutOne_CardTitle__UFpJK h3 {
  margin-top: 1em;
  font-size: 1.5rem;
  color: #292f33;
  font-weight: bold;
}
.NonConcertableLayoutOne_CardTitle__UFpJK h4 {
  font-size: 1.3rem;
  color: #5b5855;
  font-weight: normal;
}

.NonConcertableLayoutOne_VideoPreview__3TTR5 {
  /* margin: 0.5em auto;
  width: 80%;
  max-width: 20em; */
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.NonConcertableLayoutOne_Description__2QLe7 {
  margin: 0.5rem 0;
  color: #898786;
  font-size: 0.9rem;
}

.NonConcertableLayoutOne_DescriptionPlus__2-TCV {
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: #898786;
  font-size: 0.9rem;
  margin-top: 1.5rem;
}
.NonConcertableLayoutOne_DescriptionPlus__2-TCV span {
  color: #3d9dc7;
  font-weight: 900;
}

.NonConcertableLayoutOne_CardContent__1XKYq {
  margin-top: 2rem;
  padding: 2rem 2.5em;
  background-color: #f3f2f0;
}

.NonConcertableLayoutOne_Emoji__3sLGv {
  font-size: 1.8rem;
}

.NonConcertableLayoutOne_QuestionsHeader__1nNnl {
  color: #3b4578;
  margin-top: 0.7em;
  margin-bottom: 1.5rem;
}

.NonConcertableLayoutOne_QuestionTitle__777iv {
  margin-top: 0.5em;
  font-size: 1rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.NonConcertableLayoutOne_QuestionTitle__777iv p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.NonConcertableLayoutOne_CommentSection__3TPLm {
  margin-top: 2rem;
  text-align: center;
}

.NonConcertableLayoutOne_Buttons__2-2w8 {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

.NonConcertableLayoutOne_NCSection__10HbZ {
  margin-top: 1.1em;
}
.NonConcertableLayoutOne_MapImage__3v0nD {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.NonConcertableLayoutOne_Notices__3WynV {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 910px) {
  .NonConcertableLayoutOne_Card__1PqkS {
    padding: 0;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    max-width: 50rem;
    max-height: 40rem;
    outline: none;
    display: flex;
  }
  .NonConcertableLayoutOne_Card__1PqkS::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .NonConcertableLayoutOne_Card__1PqkS {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .NonConcertableLayoutOne_VideoPreview__3TTR5 {
    margin-top: 3rem;
  }

  .NonConcertableLayoutOne_LeftSection__C07SA {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .NonConcertableLayoutOne_RightSection__2rq19 {
    background-color: #f3f2f0;
    width: 50%;
    height: 100%;
    position: relative;
  }

  .NonConcertableLayoutOne_DescriptionPlus__2-TCV {
    margin-top: 0;
  }

  .NonConcertableLayoutOne_CardContent__1XKYq {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.Switch_switch__2c4MC {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 60px;
}

.Switch_switch__2c4MC input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Switch_slider__2f7Xa {
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  box-shadow: 0px 0px 55px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Switch_slider__2f7Xa:before {
  position: absolute;
  content: "";

  width: 110px;
  height: 59px;
  left: 0px;
  top: 0px;

  background: #e2e2e2;
  box-shadow: inset 0px -7px 0px #bebebe;
  border-radius: 20px;

  -webkit-transition: 0.4s;

  transition: 0.4s;
}

input:checked + .Switch_slider__2f7Xa:before {
  background: #f5a623;
  box-shadow: inset 0px -7px 0px #d28e1e;
  border-radius: 20px;
  -webkit-transform: translateX(110px);
          transform: translateX(110px);
}

input:checked + .Switch_off__1KgXI {
  color: #ffffff;
}

.concertableLayoutOne_Card__LiMIL {
  background-color: white;
  outline: none;
  overflow: scroll;
}

.concertableLayoutOne_CardHeader__2VjyO {
  display: flex;
  top: 0;
  left: 0;
  margin: 2rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.concertableLayoutOne_CardTitle__2dvu- h3 {
  margin-top: 1em;
  font-size: 1.5rem;
  color: #292f33;
  font-weight: bold;
}
.concertableLayoutOne_CardTitle__2dvu- h4 {
  font-size: 1.3rem;
  color: #5b5855;
  font-weight: normal;
}

.concertableLayoutOne_ImagePreview__3swVY {
  margin: 0.5em auto;
  width: 80%;
  max-width: 20em;
}
.concertableLayoutOne_ImagePreview__3swVY img {
  width: 100%;
}

.concertableLayoutOne_Description__3CdSe {
  margin: 0.5rem 2.5rem;
  color: #898786;
  font-size: 0.9rem;
}

.concertableLayoutOne_CardContent__2e92b {
  margin-top: 2rem;
  padding: 2rem 2.5em;
  background-color: #f3f2f0;
}

.concertableLayoutOne_Emoji__34LoL {
  font-size: 1.8rem;
}

.concertableLayoutOne_QuestionsHeader__-PiGc {
  color: #3b4578;
  margin-top: 0.7em;
  margin-bottom: 1.5rem;
}

.concertableLayoutOne_QuestionTitle__JfKrK {
  margin-top: 0.5em;
  font-size: 1rem;
  display: flex;
  font-weight: 900;
  /* justify-content: center; */
  align-items: center;
}
.concertableLayoutOne_QuestionTitle__JfKrK p {
  color: #2a2f39;
  margin-left: 0.4em;
}

/* ============================== radio form */
.concertableLayoutOne_RadioForm__2Z7BN {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.concertableLayoutOne_RadioElement__ZdKQL {
  margin: 1em 0.8em;
}

.concertableLayoutOne_RadioElement__ZdKQL label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.concertableLayoutOne_RadioElement__ZdKQL p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.concertableLayoutOne_RadioElement__ZdKQL img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 5em;
  /* border-radius: 50%; */
  /* box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14); */
}

.concertableLayoutOne_RadioElement__ZdKQL input {
  display: none;
}

.concertableLayoutOne_RadioElement__ZdKQL input[type="radio"]:checked + label img {
  border: 3px solid #e31869;
}
.concertableLayoutOne_RadioElement__ZdKQL input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* TESTING ====================================================================== */
/* .RadioForm .RadioElementTwo {
  margin: 10px 5px 5px 0;
  display: inline-block;
} */

.concertableLayoutOne_RadioFormTwo__1gHoi {
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: left; */
}

.concertableLayoutOne_RadioElementTwo__Zucnq {
  margin: 1em 1em;
}

.concertableLayoutOne_RadioElementTwo__Zucnq input[type="radio"] {
  display: none;
}

.concertableLayoutOne_RadioElementTwo__Zucnq input[type="radio"] + label + p {
  text-align: center;
  width: 8em;
}

.concertableLayoutOne_RadioElementTwo__Zucnq input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 8em;
  width: 8em;
  display: inline-block;
  position: relative;
  border: 4px solid rgb(196, 196, 196);
  border-radius: 5%;
}

.concertableLayoutOne_RadioElementTwo__Zucnq input[type="radio"] + label:hover {
  cursor: pointer;
}

.concertableLayoutOne_RadioElementTwo__Zucnq input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.concertableLayoutOne_RadioElementTwo__Zucnq input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.concertableLayoutOne_RadioElementTwo__Zucnq input[type="radio"]:checked + label i {
  display: inline-block;
}
.concertableLayoutOne_RadioElementTwo__Zucnq input[type="radio"]:checked + label + p {
  font-weight: bold;
}

/* ============ END RADIO FORM */

.concertableLayoutOne_CommentSection__39KjX {
  margin-top: 2rem;
  text-align: center;
}

.concertableLayoutOne_Buttons__13w_X {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

.concertableLayoutOne_MUIForm__3IBf4 {
  margin-left: 2em;
}

.concertableLayoutOne_NCSection__i6LfX {
  margin-top: 1.1em;
}
.concertableLayoutOne_MapImage__38B2Z {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.concertableLayoutOne_Notices__H8DP3 {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 910px) {
  .concertableLayoutOne_Card__LiMIL {
    padding: 0;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    outline: none;
    display: flex;
  }
  .concertableLayoutOne_Card__LiMIL::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .concertableLayoutOne_Card__LiMIL {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .concertableLayoutOne_Description__3CdSe {
  }

  .concertableLayoutOne_LeftSection__10Cy9 {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .concertableLayoutOne_RightSection__3p4zz {
    background-color: #f3f2f0;
    width: 50%;
    height: 100%;
    position: relative;
  }

  .concertableLayoutOne_CardContent__2e92b {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.ProfilePage_HeaderImageWrapper__3xRaW {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.ProfilePage_HeaderImageWrapper__3xRaW img {
  width: 80%;
}

.ProfilePage_ProfilePage__2v8AS h1 {
  margin: 1rem 2rem;
}
.ProfilePage_ProfilePage__2v8AS h3 {
  margin: 1rem 0 1rem 2rem;
}
.ProfilePage_SubmitButton__1MHz3 {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.OvalButton_OvalButton__1yJQV {
  display: flex;
  margin: 0 auto;
  padding: 20px 40px;
  border: 0;
  outline: 0;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 41px;
}

.OvalButton_OvalButton__1yJQV:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.Resultats_Main__SYlWI {
  padding: 0 1em;
  margin-bottom: 2em;
}

.Resultats_Title__3uuyz {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.Resultats_Title__3uuyz span {
  font-size: 1.5rem;
  font-weight: bold;
}
.Resultats_StatsIllustration__3bN4T {
  margin-top: 2em;
}
.Resultats_StatsContent__27Cf5 {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Resultats_StatsContent__27Cf5 h3 {
  font-size: 1.4rem;
  font-weight: normal;
}
.Resultats_StatsContent__27Cf5 p {
  font-size: 2rem;
  font-weight: bold;
}
.Resultats_Illustration__9_7YU {
  margin: 1em auto;
  width: 80%;
  max-width: 25em;
  display: flex;
  justify-content: center;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Resultats_Resultats__j_0WE {
    margin: 0 2em;
    margin-top: 1em;
  }
}
/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Resultats_Resultats__j_0WE {
    margin: 0 10em;
  }
}

.PageOne_SectionTitle__3bym9 {
  text-align: center;
}
.PageOne_SectionTitle__3bym9 .PageOne_Emoji__368WP {
  font-size: 2.8em;
}
.PageOne_SectionTitle__3bym9 h3 {
  font-size: 1.5em;
}
.PageOne_SectionSubtitle__-qxd8 {
  font-weight: bold;
  font-size: 0.8em;
  margin: 0.8em 2em;
}

.PageOne_EmojiText__yjUtI {
  display: flex;
  margin: 0 2em;
}
.PageOne_EmojiText__yjUtI .PageOne_Emoji__368WP {
  font-size: 1.2em;
}

.PageOne_EmojiText__yjUtI p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.PageOne_ParticiperButton__2Ap8k {
  text-align: center;
  margin: 1.5em 0;
}

.PageOne_Illustration__28V53 {
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2em auto;
}

/* Caroussel */
.PageOne_CarouselSection__3Tdn0 {
  margin: 30px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.PageOne_CarouselSection__3Tdn0::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.PageOne_CarouselItem__1_vU0 {
  flex: 0 0 auto;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 30px;
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.PageOne_CarouselItem__1_vU0:first-child {
  margin-left: 40px;
}
.PageOne_CourselItem__1cDv-:last-child {
  margin-right: 20px;
}

.PageOne_GrandesEtapes__3c4da {
  display: flex;
  justify-content: center;
}
.PageOne_GrandesEtapes__3c4da img {
  width: 80%;
  max-width: 33em;
  margin-bottom: -3em;
}
.PageOne_HeroImage__--eu1 {
  width: 100%;
  margin-bottom: 1.5em;
  z-index: -1;
}
.PageOne_HeroImage__--eu1 img {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .PageOne_HeroImage__--eu1 {
    width: 40em;
    margin: 0 auto;
    margin-bottom: 3em;
  }
  .PageOne_HeroImage__--eu1 img {
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.14);
  }
  .PageOne_Section__2m8aK {
    margin: 0 5.5em;
  }
  .PageOne_SectionTitle__3bym9 {
    display: flex;
    align-items: flex-end;
  }
  .PageOne_SectionSubtitle__-qxd8 {
    font-weight: bold;
    font-size: 0.9em;
    margin: 0.8em 0;
  }
  .PageOne_ContactForm__13ZBE {
    margin: 0 auto;
    max-width: 40em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .PageOne_HeroImage__--eu1 {
    width: 50em;
    margin: 0 auto;
    margin-bottom: 4em;
  }
  .PageOne_Section__2m8aK {
    margin: 0 20em;
  }
  .PageOne_Illustration__28V53 {
    width: 25em;
    height: 25em;
  }
}

.ContactForm_ContactForm__18_jC {
  margin: 1.5em 0.3em;
}
.ContactForm_ContactFormTitle__31tZs {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}
.ContactForm_Emoji__1Jg2z {
  font-size: 1.5rem;
}
.ContactForm_ContactFormFields__3Iiwq {
  margin: 2em;
  display: flex;
  flex-direction: column;
}
.ContactForm_Button__2U-NP {
  text-align: end;
}
.ContactForm_Success__10mlH {
  margin-left: 0.2em;
  margin-bottom: 1em;
  color: green;
  font-size: 0.8rem;
}
.ContactForm_Error__1z-Wv {
  margin-bottom: 1em;
  margin-left: 0.2em;
  color: red;
  font-size: 0.8rem;
}

.Separator_Separator__3C7I4 {
  margin: 0 auto;
  height: 4px;
  width: 90%;
  max-width: 60em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset 1px 3px 5px #a6abbd, inset 7px 4px 14px #fafbff;
  border-radius: 4px;
}

/* .Content {
  margin-top: 56px;
} */

/* Small tablets */

/* Large tablets & laptops */
@media (min-width: 960px) {
  .Layout_Content__2gYeR {
    margin-top: 0px;
  }
}

.Toolbar_Toolbar__2MLNW {
  height: 57px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #ebecf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
}

/* .Logo {
  height: 60%;
} */
.Toolbar_Logo__3c1WX h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #5b5855;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 619px) {
  .Toolbar_DesktopOnly__CsGiA {
    display: none;
  }
}

/* Small tablets */
@media (min-width: 620px) {
  .Toolbar_Toolbar__2MLNW {
    /* box-shadow: none; */
    height: 70px;
    padding: 0 40px;
    /* position: relative; */
    background-color: white;
  }
  .Toolbar_Logo__3c1WX h3 {
    font-size: 1.8rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 960px) {
  .Toolbar_Toolbar__2MLNW {
    /* height: 100px; */
    padding: 10px 80px;
  }
  .Toolbar_Logo__3c1WX h3 {
    font-size: 2rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Toolbar__2MLNW {
    padding: 10px 100px;
  }
}

.SideDrawer_SideDrawer__2rr-N {
  position: fixed;
  width: 280px;
  max-width: 60%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: #ebecf0;
  padding: 32px 16px;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 620px) {
  .SideDrawer_SideDrawer__2rr-N {
    display: none;
  }
}

.SideDrawer_Open__3vER8 {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.SideDrawer_Close__3xDaq {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.SideDrawer_Logo__39Qp_ {
  height: 11%;
  margin-bottom: 32px;
}

.NavigationItems_NavigationItems__3YXfH {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}

/* Small tablets */
@media screen and (min-width: 620px) {
  .NavigationItems_NavigationItems__3YXfH {
    flex-flow: row;
    width: 50vw;
  }

  .NavigationItems_Participer__11I8b {
    margin-left: 25px;
  }
}

/* Large tablets & laptops */
@media (min-width: 960px) {
  .NavigationItems_NavigationItems__3YXfH {
    width: 45vw;
  }
}

.NavigationItem_NavigationItem__1HWlg {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem_NavigationItem__1HWlg a {
  color: #2a2f39;
  padding: 10px 20px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 7px;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.NavigationItem_NavigationItem__1HWlg a:hover,
.NavigationItem_NavigationItem__1HWlg a:active,
.NavigationItem_NavigationItem__1HWlg a.NavigationItem_active__1Diel {
  color: #e6404d;
  font-weight: bold;
  box-shadow: inset 5px 5px 10px #a6abbd, inset -5px -5px 10px #fafbff;
}

/* Small tablets */
@media (min-width: 620px) {
  .NavigationItem_NavigationItem__1HWlg {
    margin: 10px 10px;
    padding: 10 30px;
  }
  .NavigationItem_NavigationItem__1HWlg a {
    border: none;
    box-shadow: none;
    padding: 10px 0;
  }
  .NavigationItem_NavigationItem__1HWlg a:hover,
  .NavigationItem_NavigationItem__1HWlg a:active,
  .NavigationItem_NavigationItem__1HWlg a.NavigationItem_active__1Diel {
    box-shadow: none;
    font-weight: bold;
    color: #e6404d;
  }
}

.LongButton_LongButton__1Xjdf {
  font-family: inherit;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 15px;
  outline: none;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 4px;
  cursor: pointer;
}
.LongButton_LongButton__1Xjdf:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

